
import { defineComponent, PropType } from "vue";
import { ChatMessageContentBlockSoftbreak } from "../../../../../types/ChatMessageContentBlock";

export default defineComponent({
  name: "SoftbreakBlock",
  props: {
    _block: {
      required: true,
      type: Object as PropType<ChatMessageContentBlockSoftbreak>
    }
  },
  computed: {
    block(): ChatMessageContentBlockSoftbreak {
      return this._block;
    }
  }
});
