
import { defineComponent, PropType } from "vue";
import { ChatMessageContentBlockMention } from "../../../../../types/ChatMessageContentBlock";
import { authModule } from "@/store/modules/auth";

export default defineComponent({
  name: "MentionBlock",
  props: {
    _block: {
      required: true,
      type: Object as PropType<ChatMessageContentBlockMention>
    }
  },
  computed: {
    block(): ChatMessageContentBlockMention {
      return this._block;
    },
    isMe(): boolean {
      return this.block.mention.userid === authModule.user?.id;
    }
  }
});
