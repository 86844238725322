<template>
  <span>{{ block.content }}</span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ChatMessageContentBlockText } from "../../../../../types/ChatMessageContentBlock";

export default defineComponent({
  name: "TextBlock",
  props: {
    _block: {
      required: true,
      type: Object as PropType<ChatMessageContentBlockText>
    }
  },
  computed: {
    block(): ChatMessageContentBlockText {
      return this._block;
    }
  }
});
</script>

<style scoped></style>
