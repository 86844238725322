import { ChatContentBlock } from "@/components/chat/helpers/types";
import { spaxios } from "@/api/spaxios";
import { Routes } from "@/api/routes";

interface Props {
  preview: string;
  message: ChatContentBlock[];
}

interface ChatSendMessageResponse {
  success: boolean;
  errors: Record<string, any>;
}

export async function sendChatMessage(
  p: Props
): Promise<ChatSendMessageResponse> {
  const res: ChatSendMessageResponse = { success: true, errors: {} };
  try {
    await spaxios.post(Routes.CHAT_SEND_MESSAGE, p);
  } catch (e) {
    res.success = false;
    res.errors = e.response.data.errors;
  }

  return res;
}
