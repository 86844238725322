<template>
  <ckeditor
    :editor="editor"
    v-model="editorData"
    :config="editorConfig"
    @ready="setEditor"
  />
  <div class="mt-2" />
  <div class="flex">
    <SendMessageBtn @click="sendMessage" />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Mention from "@ckeditor/ckeditor5-mention/src/mention";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

let editorInstance: any = null;

import { craftParser } from "@/components/chat/helpers/craftParser";
import {
  customItemRenderer,
  MentionCustomization
} from "@/components/chat/helpers/mentionsRenderer";
import { getChatMentionSearch } from "@/api/queries/getChatMentionSearch";
import { sendChatMessage } from "@/api/queries/sendChatMessage";
import SendMessageBtn from "@/components/chat/SendMessageBtn.vue";

export default defineComponent({
  components: { SendMessageBtn, ckeditor: CKEditor.component },
  editorInstance: null,
  data() {
    return {
      loadingSendMessage: false,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        placeholder: "Start typing something amazing here...",
        plugins: [
          EssentialsPlugin,
          Mention,
          ParagraphPlugin,
          MentionCustomization
        ],
        toolbar: {
          items: []
        },
        mention: {
          feeds: [
            {
              marker: "@",
              itemRenderer: customItemRenderer,
              feed: async (searchString: string) => {
                return (await getChatMentionSearch(searchString)).users;
              }
            }
          ]
        }
      }
    };
  },
  methods: {
    setEditor(editor) {
      editorInstance = editor;
      editorInstance.editing.view.document.on(
        "keydown",
        (evt, data) => {
          if (data.keyCode === 13 && !data.shiftKey) {
            data.preventDefault();
            evt.stop();
            this.sendMessage();
            return false;
          }
        },
        { priority: "highest" }
      );
    },
    async sendMessage() {
      if (!editorInstance || this.loadingSendMessage) {
        return;
      }

      this.loadingSendMessage = true;

      const content = craftParser(editorInstance);

      if (content.preview.length > 0) {
        const response = await sendChatMessage(content);

        if (response.success) {
          this.editorData = "";
        }
      }

      this.loadingSendMessage = false;
    }
  }
});
</script>
