export let supportsPassive = false;
export let isTouch = false;

function receiveTouch() {
  isTouch = true;

  window.removeEventListener("touchstart", receiveTouch);
}

if (typeof window !== "undefined") {
  supportsPassive = false;
  try {
    const opts = Object.defineProperty({}, "passive", {
      get() {
        supportsPassive = true;
        return true;
      }
    });
    window.addEventListener("test", null, opts);
  } catch (e) {
    // do nothing here
  }

  window.addEventListener("touchstart", receiveTouch, {
    passive: true
  });
}
