import { spaxios } from "@/api/spaxios";
import { Routes } from "@/api/routes";

interface ChatMentionSearchUser {
  name: string;
  id: string;
  userid: number;
}

interface GetChatMentionSearchResponse {
  success: boolean;
  users: ChatMentionSearchUser[];
}

export async function getChatMentionSearch(
  query: string
): Promise<GetChatMentionSearchResponse> {
  const res: GetChatMentionSearchResponse = {
    success: true,
    users: []
  };
  try {
    const { data } = await spaxios.get(Routes.CHAT_MENTION_SEARCH, {
      params: { q: query }
    });

    res.users = data.users;
  } catch (e) {
    res.success = false;
  }

  return res;
}
