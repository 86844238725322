
import { defineComponent, PropType } from "vue";
import { ChatUser } from "../../../../types/ChatUser";

export default defineComponent({
  name: "AuthorMessagePart",
  props: {
    author: {
      required: true,
      type: Object as PropType<ChatUser>,
      validator(author: ChatUser) {
        return !!author.userid;
      }
    }
  }
});
