<template>
  <span
    :class="{ 'bg-pink-400': isMe, 'bg-pink-200': !isMe }"
    class="px-1 mx-0.5 rounded"
    >{{ block.mention.id }}</span
  >
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ChatMessageContentBlockMention } from "../../../../../types/ChatMessageContentBlock";
import { authModule } from "@/store/modules/auth";

export default defineComponent({
  name: "MentionBlock",
  props: {
    _block: {
      required: true,
      type: Object as PropType<ChatMessageContentBlockMention>
    }
  },
  computed: {
    block(): ChatMessageContentBlockMention {
      return this._block;
    },
    isMe(): boolean {
      return this.block.mention.userid === authModule.user?.id;
    }
  }
});
</script>

<style scoped></style>
