
import { defineComponent } from "vue";
import { chatManagerModule } from "@/store/modules/chatManager";
import AuthorMessagePart from "@/components/chat/messageparts/AuthorMessagePart.vue";
import DateMessagePart from "@/components/chat/messageparts/DateMessagePart.vue";
import ContentsMessagePart from "@/components/chat/messageparts/ContentsMessagePart.vue";
import DynamicScroller from "@/components/virtualization/DynamicScroller.vue";
import DynamicScrollerItem from "@/components/virtualization/DynamicScrollerItem.vue";

export default defineComponent({
  props: {
    jumpToBottom: {
      required: true,
      type: Boolean
    }
  },
  name: "ChatMessages",
  components: {
    ContentsMessagePart,
    DateMessagePart,
    AuthorMessagePart,
    DynamicScroller,
    DynamicScrollerItem
  },
  computed: {
    messages() {
      return chatManagerModule.chatMessages;
    }
  },
  data() {
    return {
      initial: false
    };
  },
  watch: {
    messages() {
      if (!this.initial) {
        this.$nextTick(() => {
          this.initial = true;

          this.scrollToBottom();
        });
      }
      if (this.jumpToBottom) {
        this.scrollToBottom();
      }
    }
  },
  methods: {
    scrollToBottom() {
      ((this.$refs.scroller as unknown) as any)?.scrollToBottom();
    }
  }
});
