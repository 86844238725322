import { editor } from "@ckeditor/ckeditor5-core";
import {
  ChatContentBlock,
  ChatContentBlockType,
  ChatContentMentionBlock,
  ChatContentTextBlock,
  CkeditorLazyNode,
  UnsentChatMessage
} from "@/components/chat/helpers/types";

export function craftParser(instance: editor.Editor): UnsentChatMessage {
  const unsentChatMessage: UnsentChatMessage = {
    message: [],
    preview: ""
  };

  const roots = [...(instance.model.document as any).roots].filter(
    ({ rootName }) => rootName !== "$graveyard"
  );

  if (roots.length < 1) {
    return unsentChatMessage;
  }

  roots[0]._children._nodes.forEach((node: CkeditorLazyNode) => {
    const chatContentBlock: ChatContentBlock = {
      blocks: [],
      type: "paragraph"
    };

    let preview = "";

    node._children._nodes.forEach(block => {
      if (block.name && block.name === "softBreak") {
        chatContentBlock.blocks.push({
          id: 0,
          type: ChatContentBlockType.Softbreak,
          content: ""
        });
        preview += "\n";
        return;
      }

      const mention = block._attrs.get("mention");

      if (mention) {
        const chatBlock: ChatContentMentionBlock = {
          content: block._data,
          id: 0,
          mention: { userid: mention.userid, name: mention.name },
          type: ChatContentBlockType.Mention
        };

        preview += block._data;
        chatContentBlock.blocks.push(chatBlock);
        return;
      }

      const textBlock: ChatContentTextBlock = {
        content: block._data,
        id: 0,
        type: ChatContentBlockType.Text
      };

      if (!block._data.replaceAll(" ", "")) {
        textBlock.content = "<12spacer12>";
      }

      preview += block._data;
      chatContentBlock.blocks.push(textBlock);
    });

    if (chatContentBlock.blocks.length > 0) {
      unsentChatMessage.message.push(chatContentBlock);
      unsentChatMessage.preview += preview;
    }
  });

  return unsentChatMessage;
}
