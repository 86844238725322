// CKEDITOR TYPES

export interface CkeditorMentionData {
  id: string;
  _text: string;
  userid: number;
  name: string;
}

export interface CkeditorLazyChildNode {
  _attrs: Map<"mention", CkeditorMentionData>;
  _data: string;
  name?: "softBreak";
}

export interface CkeditorLazyNode {
  name: string;
  _children: {
    _nodes: CkeditorLazyChildNode[];
  };
}

// OUR CONVERSION TYPES
export enum ChatContentBlockType {
  Text = "text",
  Mention = "mention",
  Softbreak = "softbreak"
}

export interface ChatContentBaseBlock {
  id: number;
  content: string;
  type: ChatContentBlockType;
}

export interface ChatContentSoftbreakBlock extends ChatContentBaseBlock {
  type: ChatContentBlockType.Softbreak;
}

export interface ChatContentTextBlock extends ChatContentBaseBlock {
  type: ChatContentBlockType.Text;
}

export interface ChatContentMentionBlock extends ChatContentBaseBlock {
  mention: {
    userid: number;
    name: string;
  };
  type: ChatContentBlockType.Mention;
}

export interface ChatContentBlock {
  type: "paragraph";
  blocks: (
    | ChatContentTextBlock
    | ChatContentMentionBlock
    | ChatContentSoftbreakBlock
  )[];
}

export interface UnsentChatMessage {
  preview: string;
  message: ChatContentBlock[];
}
