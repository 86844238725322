
import { defineComponent, PropType } from "vue";
import { ChatMessageContent } from "../../../../types/ChatMessageContent";
import TextBlock from "@/components/chat/messageparts/blocks/TextBlock.vue";
import { ChatMessageContentBlockType } from "../../../../types/ChatMessageContentBlock";
import MentionBlock from "@/components/chat/messageparts/blocks/MentionBlock.vue";
import SoftbreakBlock from "@/components/chat/messageparts/blocks/SoftbreakBlock.vue";

export default defineComponent({
  name: "ContentsMessagePart",
  components: { SoftbreakBlock, MentionBlock, TextBlock },
  props: {
    _content: {
      required: true,
      type: Object as PropType<ChatMessageContent>,
      validator(_content: ChatMessageContent) {
        return !!_content.type && !!_content.blocks;
      }
    }
  },
  computed: {
    content(): ChatMessageContent {
      return this._content;
    },
    contentTypes() {
      return ChatMessageContentBlockType;
    }
  }
});
