<template>
  <div class="mt-5 messages-list-container overflow-hidden">
    <DynamicScroller
      ref="scroller"
      :items="messages"
      :min-item-size="100"
      class="h-full scrolling-auto"
      v-if="messages.length > 0"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :watch-data="true"
          :item="item"
          :active="!!active"
          :data-active="!!active"
          :size-dependencies="[item.preview]"
          :data-index="index"
          class="message w-full"
        >
          <div class="w-full">
            <div class="flex content-center border-b-2 border-gray-100 pb-2">
              <img
                :src="item.author.image"
                class="h-6 w-6 rounded-full border-pink-900 border-2 mr-2"
              />
              <div>
                <div class="flex">
                  <author-message-part :author="item.author" />
                  <div class="ml-2" />
                  <date-message-part :created-at="item.createdAt" />
                </div>
                <contents-message-part
                  v-for="content in item.contents"
                  :key="content.id"
                  :_content="content"
                />
              </div>
            </div>
          </div>
        </DynamicScrollerItem>
      </template>
      <template #after>
        <slot></slot>
      </template>
    </DynamicScroller>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { chatManagerModule } from "@/store/modules/chatManager";
import AuthorMessagePart from "@/components/chat/messageparts/AuthorMessagePart.vue";
import DateMessagePart from "@/components/chat/messageparts/DateMessagePart.vue";
import ContentsMessagePart from "@/components/chat/messageparts/ContentsMessagePart.vue";
import DynamicScroller from "@/components/virtualization/DynamicScroller.vue";
import DynamicScrollerItem from "@/components/virtualization/DynamicScrollerItem.vue";

export default defineComponent({
  props: {
    jumpToBottom: {
      required: true,
      type: Boolean
    }
  },
  name: "ChatMessages",
  components: {
    ContentsMessagePart,
    DateMessagePart,
    AuthorMessagePart,
    DynamicScroller,
    DynamicScrollerItem
  },
  computed: {
    messages() {
      return chatManagerModule.chatMessages;
    }
  },
  data() {
    return {
      initial: false
    };
  },
  watch: {
    messages() {
      if (!this.initial) {
        this.$nextTick(() => {
          this.initial = true;

          this.scrollToBottom();
        });
      }
      if (this.jumpToBottom) {
        this.scrollToBottom();
      }
    }
  },
  methods: {
    scrollToBottom() {
      ((this.$refs.scroller as unknown) as any)?.scrollToBottom();
    }
  }
});
</script>

<style>
.message {
  display: flex;
  min-height: 32px;
  padding: 12px;
  box-sizing: border-box;
}
.vue-recycle-scroller__item-view {
  @apply w-full;
}

.messages-list-container {
  height: 63vh;
}
</style>
