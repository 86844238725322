<template>
  <div class="hidden sm:block text-sm text-gray-400 self-center">
    {{ timeFriendly }}
  </div>
</template>

<script>
import { defineComponent } from "vue";
import spacetime from "spacetime";

export default defineComponent({
  name: "DateMessagePart",
  props: {
    createdAt: {
      required: true,
      type: String
    }
  },
  computed: {
    timeFriendly() {
      return spacetime(new Date(this.createdAt)).format("nice-full");
    }
  }
});
</script>

<style scoped></style>
