<template>
  <div class="message-box p-2">
    <div class="flex flex-col">
      <ChatMessages :jump-to-bottom="jumptoBottom" />
      <div>
        <MessageCrafter>
          <div class="self-center content-center ml-2">
            <div class="flex items-center">
              <button
                @click="jumptoBottom = !jumptoBottom"
                type="button"
                aria-pressed="false"
                aria-labelledby="toggleLabel"
                :class="{
                  'bg-indigo-600': jumptoBottom,
                  'bg-gray-200': !jumptoBottom
                }"
                class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span class="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  :class="{
                    'translate-x-5': jumptoBottom,
                    'translate-x-0': !jumptoBottom
                  }"
                  class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                ></span>
              </button>
              <span class="ml-3" id="toggleLabel">
                <span class="text-sm font-medium text-gray-900">
                  Auto-scroll to bottom
                </span>
              </span>
            </div>
          </div>
        </MessageCrafter>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MessageCrafter from "@/components/chat/MessageCrafter";
import { chatManagerModule } from "@/store/modules/chatManager";
import ChatMessages from "@/components/chat/ChatMessages";

export default defineComponent({
  name: "Chat",
  components: { ChatMessages, MessageCrafter },
  beforeMount() {
    chatManagerModule.getMessages();
  },
  data() {
    return {
      jumptoBottom: true
    };
  }
});
</script>

<style scoped></style>
